.fullNavbar {
  /* background-color: red; */
  width: 78.8vw;
}
.container1 {
  margin: 20px 20px;
  padding: 25px 50px;
  background-color: #fff;
  border-radius: 12px;
}

.container1 .card1 {
  height: 300px;
  margin: 20px;
  width: 300px;
  border-radius: 12px;
  background-color: #f5f5f5;
}
.cardbody {
  margin: 10px;
  padding: 10px;
  border-radius: 12px;
}
.cardbody .cardchild {
  margin: 8px;
  padding: 6px;
}

.cardchild p {
  margin: 12px;
  padding: 5px;
}
.searchbox {
  margin: 5px;
  padding: 5px;
}
.inputsearchbox {
  width: 980px;
  border: 2px solid darkgray;
  border-radius: 3px;
  margin-left: 35px;
}
.searchbox .form-control {
  width: 950px !important;
  border-radius: 12px !important;
}
.inputsearchbox::after {
  border: 1px solid darkblue;
}
.settingtxt {
  margin-left: 55px;
  padding: 10px;
  margin-top: 10px;
}
.navbarhead {
  display: flex;
  justify-content: space-between;
}

.closebtn {
  height: 35px;
  width: 150px;
  margin-top: 20px;
  margin-right: 5px;
  padding: 7px;
  border: 1px solid skyblue;
}
.on-off{
  border: none!important;
  background-color: #f5f5f5;
}
.hover-effect {
  cursor: pointer;
}

.hover-effect:hover {
  color: #007bff; /* Change this to your desired hover color */
  text-decoration: underline; /* Optional: adds underline on hover */
}
