.cninputchild {
  margin-left: 19px;
}
.cninputchild input[type="text"],
input[type="date"],
textarea {
  background-color: rgb(245, 245, 245);
  border-radius: 9px;
  margin-top: 10px;
  font-size: 13px;
  height: 40px;
  width: 260.8px !important;
}
.cninputchild select {
  width: 260.8px !important;
}

.cntext-style {
  font-size: 12px;
  color: var(--Black-medium-text, rgba(0, 0, 0, 0.5));
  font-size: 14px;
  color: grey;
  font-weight: 600;
  margin-top: 15px;
}

.subjectbox-cn {
  margin-left: 19px;
}
.subjectbox-cn > input {
  background-color: #f6f6f6;
  border-radius: 7px;
  padding: 12px;
  color: black;
  height: 40px;
  width: 35.3vw;
  margin-top: 0px !important;
}

.subjectbox-cn::placeholder {
  color: var(--Black-light-text, rgba(0, 0, 0, 0.25));
}

.salespersonbox {
  margin-left: 19px;
}

.cnsubtotal-box {
  border-radius: 7px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background: #fff;
}

.cn-bottom-buttons {
  margin-top: 40px;
  margin-right: 25px;
  margin-bottom: 30px;
}

.border-box {
  box-sizing: border-box;
}