@media print {
    body {
      -webkit-print-color-adjust: exact;
    }
  
    .container-fluid, .page-content, .head-container, table {
      background-color: #fff !important;
      color: #000;
    }
  
    .table thead th, .table tbody td {
      border: 1px solid #dee2e6 !important;
      padding: 8px;
    }
  
    .d-flex {
      display: flex;
    }
  
    .align-items-center {
      align-items: center;
    }
  
    .justify-content-center {
      justify-content: center;
    }
  
    .justify-content-between {
      justify-content: space-between;
    }
  
    .ml-1 {
      margin-left: 4px;
    }
  
    .ml-2 {
      margin-left: 8px;
    }
  
    .mt-1 {
      margin-top: 4px;
    }
  
    .mt-2 {
      margin-top: 8px;
    }
  
    .mt-4 {
      margin-top: 16px;
    }
  
    .mb-1 {
      margin-bottom: 4px;
    }
  
    .mb-5 {
      margin-bottom: 20px;
    }
  }
  
.get-paid-table th,.get-paid-table td{
    padding: 10px 20px;
    font-size: 12px;
}