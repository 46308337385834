.mcontaine {
  margin: 10px;
  padding: 10px;
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}
.navpartandtable {
  display: flex;
  flex-direction: column;
}
.mcontaine .card {
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.typeof {
  display: flex !important;
  margin-left: 0px;
  margin-top: 11px;
}

.firstLine {
  margin-left: 20px;
}
.textandinput {
  margin: 5px;
  padding: 5px;
}
.rowline {
  margin-top: 10px;
}
.inputchild {
  margin-left: 19px;
}
.inputchild input[type="text"],
textarea {
  background-color: rgb(245, 245, 245);
  border-radius: 9px;
  margin-top: 10px;
  font-size: 13px;
  height: 40px;
  width: 250px !important;
}

.inputchild2 {
  margin-left: 19px;
}
.inputchild2 input[type="text"],
textarea {
  background-color: rgb(245, 245, 245);
  border-radius: 9px;
  margin-top: 10px;
  height: 46px;
  width: 209px !important;
}
.shopingadrees {
  color: var(--Black-main-text, rgba(0, 0, 0, 0.8));
  font-family: "Avenir Next";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.buttonClass {
  margin-left: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 60px;
  justify-content: space-between;
}

.three-childbtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-3 {
  display: inline-flex;
  height: 46px;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid var(--Primary, #3c7bd4);
  background: var(--Primary, #3c7bd4);
}
.btn-1 {
  display: inline-flex;
  height: 46px;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #3c7bd4;
  flex-shrink: 0;
  border-radius: 7px;
  border-radius: 7px;
  border: 1px solid var(--Primary, #3c7bd4) !important;
  background: var(--Card-color, #fff);
}
.text3 {
  color: var(--Black-light-text, rgba(0, 0, 0, 0.25));
}
.text2 {
  font-size: 12px;
  color: var(--Black-medium-text, rgba(0, 0, 0, 0.5));
}
.deletebtn {
  margin-top: 43px;
}
.btn-link {
  text-decoration: 5px underline !important;
  border-radius: 3px;
  text-decoration-color: var(--Secondary, #e4b535) !important;
  text-underline-offset: 25px !important;
  outline: none !important;
  background-color: #fff !important;
}
.btns {
  border: none;
  outline: none;
}
.withoutbtn {
  border: none !important;
  outline: none !important;
  background-color: #fff !important;
  color: var(--Black-light-text, rgba(0, 0, 0, 0.25));
}

.arrow-child {
  align-items: center !important;
  margin-left: 12px;
}
.navpart {
  margin: 8px;
  padding: 12px;
}
.btnprevious {
  margin-top: 20px;
}
.vl {
  border-left: 2px solid rgba(64, 110, 248, 0.944);
  height: 400px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
  margin-top: 46%;
}

.shopingaddress2 {
  color: #3c7bd4;
}
.itemlist {
  color: red;
}

.inputchild23 {
  margin-left: 10px;
}
.inputchild23 input[type="text"],
textarea {
  background-color: rgb(245, 245, 245);
  border-radius: 9px;
  margin-top: 10px;
  font-size: 13px;
  height: 40px;
  width: 180px !important;
}

.bottom-buttons{
  margin-top: 40px;
  margin-right: 32px;
  margin-bottom: 30px;
}
.val-text{
  color: red;
}