/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  font-family: Avenir Next W01, Lato, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif !important;
  line-height: 1.2 !important;
  color: black !important;
  background-color: #f2f4f8 !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.atv,
.str {
  color: #05AE0E;
}

.tag,
.pln,
.kwd {
  color: #3472F7;
}

.atn {
  color: #2C93FF;
}

.pln {
  color: #333;
}

.com {
  color: #999;
}

.highlight {
  background-color: #f8f9fa;
  padding: 20px;
}

.highlight pre code {
  font-size: inherit;
  color: #212529;
}

.nt {
  color: #2f6f9f;
}

.na {
  color: #4f9fcf;
}

.rangeslider {
  width: 100px;
  height: 5px !important;
}

.black-color .rangeslider__fill {
  background-color: #212529 !important;
}

.black-color .rangeslider__handle {
  background-color: #212529 !important;
}

.blue-color .rangeslider__fill {
  background-color: #637AAE !important;
}

.blue-color .rangeslider__handle {
  background-color: #637AAE !important;
}

.rangeslider__handle {
  width: 15px !important;
  height: 15px !important;
}

.rangeslider__handle:after {
  height: 0px !important;
}


/* background-color: #096c8c !important; */


.btn.btn-primary {
  background-color: #3C7BD4 !important;
  border-color: #096b8c81 !important;
  border: 0px !important;
}

.btn {
  font-size: 16px !important;
  border: 0px !important;
}

.sidebar-nav .metismenu > ul .active {
  color: rgb(255, 255, 255) !important;
  background: var(--Primary, #3C7BD4) !important;
  border-radius: 9px;
  /* color: var(--Primary, #3c7bd4); */
}
.sidebar-nav .metismenu > ul li.active {
  color: #fff !important;
  background: var(--Primary, #3C7BD4) !important;
  border-radius: 9px;
  flex-shrink: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.sidebar-nav .metismenu > li.active > a {
  color: #3C7BD4 !important;
  background: #e0dddd !important;
  border-radius: 9px;
}

.sidebar-nav .metismenu > li.active {
  /* border-radius: 5px !important; */
  /* border-color: #099bfdfc !important; */
  border-radius: 9px;
  /* background: var(--Primary, #3C7BD4); */
  color: rgb(255, 255, 255) !important;
}

.sidebar-nav .metismenu ul a::before {
  content: true !important;
}



.borderrr {
  border: 1px solid #c1bebe;
  border-radius: 5px;
  padding: 5px 0px;
  margin: 1px -5px;
}

.user-account {
  margin: 21px 6px !important;
}

.navbar-fixed-top .navbar-brand button {
  padding: 0;
  color: #000000 !important;
}

.pointer {
  cursor: pointer !important;
}

.navbar-fixed-top {
  padding: 1px 10px !important;
  background: #fdfdfd;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2e2e2e #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #2e2e2ecb;
  border-radius: 10px;
  /* border: 3px solid #ffffff; */
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.container-fluid {
  width: 100% !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: auto;
  margin-left: auto;
}
.item-page{
  width: 100% !important;
  padding: 20px!important;
  background-color: #f5f5f5; 
}

.valid{
  color: red;
}
.tabel-card {
  /* border: 1px solid #ccc; */
  /* border-radius: 10px; */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Calibri', sans-serif;
  color: black;

}

.navbar-fixed-top .navbar-brand {
  padding: 15px 0;
  margin-left: 18px !important;
  position: relative;
}

.mr-r-12 {
  margin-right: 12px !important;
}

.btn-light {
  background-color: #1e62eb0f !important;
  border-color: #1e62eb0f !important;
}

.btn-light:hover {
  background-color: #1e62eb1a !important;
  border-color: #1e62eb1a !important;
}

.spinner {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border: 4px solid #a9a9a9;
  border-top-color: #3f83f9;
  border-radius: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(1turn);
  }
}


.center-div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 88vh;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {

  border-radius: 7px !important;
  background: #096b8c0d !important;
}

.card:hover {
  border-color: #e2e2e2 !important
}

/* Change background color on hover */
.nav-link:hover {
  background-color: #2C93FF !important;
}

/* Change background color when tab is active */
.nav-link.active {
  background-color: #2C93FF !important;
}

.online {
  color: #2C93FF !important;
}

.new_timeline {
  color: #000000 !important;
}

.form-control:hover {
  border-color: #2C93FF !important;
}

.container-item {
  border-radius: 2px !important;
  /* border: 1px solid #ccc; */
  /* border-radius: 10px; */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  color: black;
  width: 100% !important;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}

.item-head {
  margin-top: 5px;
  margin: 5px;

}

.form-label {
  color: 'rgba(0, 0, 0, 0.50)'!important;
  font-Size: 12!important;
  /* font-Family: 'Avenir Next'!important;  */
  font-Weight: '500'!important;
  word-Wrap: 'break-word'!important;

}
.input-container {
  position: relative;
  width: 100%;
}

.custom-input {
  width: 200px;
  height: 40px;
  background: #F6F6F6;
  border-radius: 7px;
  padding: 10px;
  font-size: 14px;
  font-family: 'Avenir Next';
  font-weight: 500;
  border: 1px solid #2C93FF; /* Initial border color */
  transition: border-color 0.3s ease; /* Add transition effect for smooth change */
}

.custom-input:focus,
.custom-input:valid {
  border-color: #337ab7; /* Change border color when focused or has value */
}

.label {
  position: absolute;
  top: 14px;
  left: 15px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-family: 'Avenir Next';
  font-weight: 500;
}

.custom-input::placeholder {
  position: absolute;
  top: -21px;
  left: 0;
  color: rgba(0, 0, 0, 0.50);
  font-size: 12px;
  font-family: 'Avenir Next';
  font-weight: 500;
  word-wrap: break-word;
}

/* Add this CSS to your stylesheet */
/* Add this CSS to your component's CSS file or style block */
.table-custom thead {
  background-color: transparent !important;
}
/* Add this CSS to your stylesheet */
.table input[type="checkbox"] {
  width: 15px; /* Set desired width */
  height: 14px; /* Set desired height */
}
.tabel-border{
  border: 0px!important;
}
.circular-button {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the button circular */
  background-color: #dedcdced; /* Adjust background color as needed */
  border: 1px solid #ccc; /* Add border for better visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  cursor: pointer;
  padding: 0; /* Ensure no padding inside the button */
}


/* Invoice New */

.icon {
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-title {
  font-Size: '14px';
  color: 'grey'
}

.form-control {
  font-Size: '14px';
  color: 'grey'
}
input::placeholder{
font-size: 14px !important;
color: var(--Black-light-text, rgba(0, 0, 0, 0.5)) !important;

}

.input-field-name {
  background-color: '#F6F6F6';
  border: "none";
  padding: "12px";
}

.title {
  letter-spacing: 1px;
}

.card-style {
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.customer-info {
  color: #3C7BD4;
  font-size: 16px;
  font-family: 'Avenir Next';
  font-weight: 600;
  word-wrap: break-word;
}

.customer-notes-label {
  color: #3C7BD4;
  font-size: 14px;
  font-family: 'Avenir Next';
  font-weight: 600;
  word-wrap: break-word;
}

.cancel-button {
  border-radius: 7px;
  border: 1px solid var(--Primary, #3C7BD4);
  background: #FFF;
}

.create-button {
  border-radius: 7px;
  background: var(--Primary, #3C7BD4);
}

.custom-datepicker {
  background-color: #F6F6F6;
  padding: 16px;
  border: none;
  width: 135%;
  border-radius:5px
}

.dropdown-field {
  background-color: #F6F6F6;
  padding: 14px;
  border: none;
  width: 100%;
  border-radius: 5px;
}

.subtotal-box {
  border-radius: 7px;
  border: 1px dashed rgba(0, 0, 0, 0.20);
  background: #FFF;
}

.custom-button {
  border-radius: 7px;
  border: 1px solid var(--Primary, #3C7BD4);
  background: #FFF;
}

.vertical-line {
  display: inline-block;
}
/* Add this CSS to change font color on hover */
.table-hover tbody tr:hover > td {
  color: black; /* Change this to your desired hover font color */
}

.report-page{
  width: 100%!important;
  padding-left: 0 !important;
  padding-right: 0!important;
  background-color: white !important;
}
.form-control{
  height: 46px !important;
}

.estimatess-dropdown-container {
  position: relative;
}
.estimates-dropdown {
  position: absolute;
  top: 44px;
  left: -140px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 7px;
}

.estimates-option1 {
  /* border-top-left-radius: 7px;
    border-top-right-radius: 7px; */
  margin: 0px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  padding: 10px 5px 10px 5px;
}
/* .estimates-option2 {
  padding: 10px;
} */
.estimates-option1:hover {
  background-color: #3c7bd4;
  color: white;
}

/* .estimates-option2:hover {
  background-color: #3c7bd4;
  color: white;
} */
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container .customDatePickerWidth > div > div.react-datepicker__input-container input {
width: 100%;
}

.customer-grid-container {
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;
}

.customer-flex-item {
flex: 1 1;
padding: 10px;
}

.customer-subtotal-box {
padding: 20px;
border-radius: 7px;
border: 1px dashed rgba(0, 0, 0, 0.2);
background: #fff;
}
.cnotes-textarea {
width: 100% !important;
background-color: rgb(246, 246, 246) !important;
}
.customer-tc-textarea {
width: 100% !important;
background-color: rgb(246, 246, 246) !important;
}

/****Popup*****/

.customer-popup-overlay {
position: fixed;
top: 0;
left: 100px;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: flex-start;
z-index: 2;
}
.customer-popup-content {
width: 600px;
background-color: white;
padding: 15px !important;
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
margin-top: 80px;
z-index: 3;
max-width: 80%;
height: auto;
}
.popupinvoice-container {
padding: 10px;
}

.customer-headline-label {
top: 14px;
left: 15px;
font-size: 14px;
font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
font-weight: 400;
}
.headline {
font-size: 15px;
font-weight: 400;
}
.customer-option {
font-weight: 500;
color: rgb(33, 37, 41);
font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
}
.customer-rdiobox-label {
top: 14px;
left: 15px;
color: rgba(0, 0, 0, 0.25);
font-size: 14px;
font-family: "Avenir Next";
font-weight: 500;
}

.customer-generate-inputfield {
width: 140px;
}

.customer-generate-inputfield2 {
width: 210px;
margin-left: 10px;
}

.salesperson-btn {
border: 1.4px solid #c1bebe !important;
border-radius: 10px;
background-color: #fff;
color: #555555;
}

.btn:focus {
outline: none;
box-shadow: none;
}
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container .customDatePickerWidth > div > div.react-datepicker__input-container input {
width: 100%;
}

.customer-grid-container {
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;
}

.customer-flex-item {
flex: 1 1;
padding: 10px;
}

.customer-subtotal-box {
padding: 20px;
border-radius: 7px;
border: 1px dashed rgba(0, 0, 0, 0.2);
background: #fff;
}
.cnotes-textarea {
width: 100% !important;
background-color: rgb(246, 246, 246) !important;
overflow: hidden;
}
.invoicetextarea {
overflow: hidden;
width: 100% !important;
background-color: rgb(246, 246, 246) !important;
}

/****Popup*****/

.customer-popup-overlay {
position: fixed;
top: 0;
left: 100px;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: flex-start;
z-index: 2;
}
.customer-popup-content {
width: 600px;
background-color: white;
padding: 15px !important;
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
margin-top: 80px;
z-index: 3;
max-width: 80%;
height: auto;
}
.popupinvoice-container {
padding: 10px;
}

.customer-headline-label {
top: 14px;
left: 15px;
font-size: 14px;
font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
font-weight: 400;
}
.headline {
font-size: 15px;
font-weight: 400;
}
.customer-option {
font-weight: 500;
color: rgb(33, 37, 41);
font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
}
.customer-rdiobox-label {
top: 14px;
left: 15px;
color: rgba(0, 0, 0, 0.25);
font-size: 14px;
font-family: "Avenir Next";
font-weight: 500;
}

.customer-generate-inputfield {
width: 140px;
}

.customer-generate-inputfield2 {
width: 210px;
margin-left: 10px;
}

.salesperson-btn {
border: 1.4px solid #c1bebe !important;
border-radius: 10px;
background-color: #fff;
color: #555555;
}

.btn:focus {
outline: none;
box-shadow: none;
}

.invoice-bottombtns {
margin-top: 40px;
margin-bottom: 10px;
}

/***** New Customer Modal *****/

.modalinputchild {
margin-left: 45px;
}

.modalinputchild input[type="text"],
input[type="date"],
textarea {
background-color: rgb(245, 245, 245);
border-radius: 9px;
margin-top: 10px;
font-size: 13px;
height: 35px;
width: 200px !important;
}

.modalinputchild select {
width: 200px !important;
}

/***** Sales Person Modal *****/

.modalsalesinput {
margin-left: 45px;
}

.modalsalesinput input[type="text"] {
background-color: rgb(245, 245, 245);
border-radius: 9px;
margin-top: 5px;
font-size: 13px;
height: 35px;
width: 400px !important;
}
.cursor-pointer{
  cursor: pointer !important;
}
#wrapper{
  position: fixed;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  background-image: url(./asset/images/newImages/abstract1.png);
  background-size: cover;
}

.getstarted:hover{ 
color: black !important;
background-color: red;
}  
.swal-button--register {
  background-color: #6ab04c; /* Replace with your desired color */
}