/* Templates.css */
.templates-container {
    display: flex;
    height: auto; 
  }
  
  .left-purchase {
    width: 20%;
    background-color: #fffdfd;
    overflow-y: auto;
    
  }
  .purchase-row{
    height: 40px;
    padding-top: 5px;
  }

  .purchase-row:hover {
    background-color: #ECEFF3; 
    color:#28a745;
  }
  
  .right-purchase     {
    flex: 1; /* Take remaining width */
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  
  .bill{
    background-color: rgb(236, 239, 243);
    height: 40px;
  }
  
   /* Templates.css */
  .purchase-border {
  border: 4px solid; 
  }
  .section {
    margin-top:10px;
     /* Take remaining height */
  }
  .section1 {
    margin-top:0px;
    flex: 1; /* Take remaining height */
  }
  .hello {
  justify-content: center;
  align-items: center;
  width: 100%;
  }
  .vertical-line {
    border-left: 1px solid #000;
  }

  .middle-line {
    border-left: 1px solid #d0caca;
  }
  
  .horizontal-line {
    border-top: 1px solid #d2cdcd;
    width: auto; 
  }

  .payment-gateway{
    background-color: #ffffff; 
    border: 1px solid #e0e0e0; 
    border-radius: 8px; 
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin: 12px; 
  }
 
  .payment-gateway .top-bar-icons {
    display: inline-block;
    padding: 8px;
    cursor: pointer;
    position: relative; 
  }

  .payment-gateway .top-bar-icons.active {
    color: #007bff; /* You can replace this with your desired active text color */
  }
  
  .payment-gateway .top-bar-icons.active::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px; /* You can adjust the thickness of the underline */
    background-color: #007bff; /* You can replace this with your desired active underline color */
  }
  
  .payment-gateway .top-bar-icons:hover {
    background-color: #e0e0e0; /* You can replace this with your desired hover background color */
  }
  
  .print-icon{
    margin-right: 30px;
    display: flex;
    align-items: center;
  }
 
  .payment-gateway button {
    background-color: #28a745; /* Green background color for the button */
    color: #ffffff; /* Text color for the button */
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .print-section{
    border: 1px solid #e0e0e0; /* Border */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
  }
   
  .statment-navbar{
    background-color: #ffffff; 
    border: 1px solid #e0e0e0; 
    /* border-radius: 8px;  */
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin: 12px; 
  }
 
  .statment-navbar .top-bar-icons {
    display: inline-block;
    padding: 8px;
    cursor: pointer;
    position: relative; 
  }

  .statment-navbar .top-bar-icons.active {
    color: #007bff; /* You can replace this with your desired active text color */
  }
  
  .statment-navbar .top-bar-icons.active::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px; /* You can adjust the thickness of the underline */
    background-color: #007bff; /* You can replace this with your desired active underline color */
  }
  
  .statment-navbar .top-bar-icons:hover {
    background-color: #e0e0e0; /* You can replace this with your desired hover background color */
  }
  
  /* Your CSS file */
  .invoicedetail-data {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .invoicedetail-data .bill {
  margin: 10px 0;
  }
  
  .invoicedetail-data table {
  width: 100%;
  }
  
  .invoicedetail-data table th {
  background-color: #f0f0f0;
  font-weight: bold;
  text-align: center;
  }
  
  .invoicedetail-data table td {
  text-align: center;
  }
   /* Add this style to your CSS file or inline styles */
.overview-content {
  display: flex;
}

.first-col {
  flex: 0 0 30%; /* This sets the first column to be 30% of the container */
  padding: 10px; /* Add padding as needed */
}

.second-col {
  flex: 0 0 70%; /* This sets the second column to be 70% of the container */
  padding: 10px; /* Add padding as needed */
}

.accordion-container {
  width: 100%;
}

.accordion-item {
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  background-color: #f9f9f9;
  user-select: none;
}

.accordion-title.active {
  background-color: #e5e5e5;
}

.accordion-content {
  padding: 12px;
  background-color: #fff;
}

.row-container {
  display: flex;
}

.column {
  flex: 1;
  padding: 5px;
}

.accordion-container {
  flex: 2;
  padding: 10px; 
}

.col-left{
  flex: 0 0 20%; 
  padding: 0px; 
  margin:0;
}

.col-right{
  flex: 0 0 80%; 
  padding: 0px; 
  margin:0;
}

.inner-cols-container {
  display: flex;
}

.inner-col {
  flex: 1;
  margin-right: 10px; 
}

.edithover:hover {
  color: rgb(42, 153, 163); 
  text-decoration: underline; 
}
/* comments card */
.custom-card {
  width: 700px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-card-body {
  padding: 20px;
}

.customer-card{
  width:100%;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.customer-card-body {
  padding: 20px;
}