/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 50px;
  text-align: center;
  line-height: 34px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 30px;
  left: 5px; /* Adjust the left positioning as needed */
  bottom: 2px; /* Adjust the bottom positioning as needed */
  background-color: white;
  border-radius: 45%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

input:checked + .slider {
  background-color: #007bff;
  border: 1px solid #007bff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
}

.tooltip {
  position: absolute;
  display: none;
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  font-size: 14px;
  width: 200px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add a subtle box shadow */
}

.tooltip strong {
  color: #ecf0f1;
}

.tooltip br {
  display: none; /* Optional: Remove line break if not needed */
}

li:hover .tooltip {
  display: block;
}
.cont {
  display: flex;
  border-radius: 9px;
  height: 44.8px;
}
.child-cont {
  width: 100%;
  justify-content: flex-end;
  border-radius: 9px;
}

.child-cont:hover {
  background-color: #e0dddd;
}
.child-cont:hover .circlechild {
  color: #007bff;
}
.cir {
  display: flex;
  border-radius: 18px;
}

.pro {
  text-align: left;
  flex-grow: 1;
  align-items: flex-start;
}

.cont:hover {
  background-color: #e0dddd;
  height: 44.8px;
}

a.text.pro::before {
  display: none;
}

a {
  cursor: pointer;
}
.pro-child {
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.circlechild {
  color: #fff;
}
.cont:hover .circlechild {
  color: #007bff;
}

.cont:hover .circlechild::after {
  color: #fff;
}

.dropdown {
  padding: 0px !important;
  width: 80px !important;
  margin-right: 30px;
  border-radius: 12px !important;
  border: none !important;
  align-items: flex-start;
  position: relative !important;
}
.dropdown .item {
  position: absolute !important;
  display: none;
  margin-right: 200px !important;
  border-radius: 12px;
  box-shadow: 5px gray;
}
.dropdown button {
  align-items: flex-start;
  margin-right: 350px;
}

.dropdown:hover {
  background-color: #fff !important;
}
.dropdown:hover .item {
  width: 200px;

  background-color: #f5f5f5;
  color: black;
  display: block;
  border: none;
  outline: l;
}
.item {
  text-align: left;
}
.item p {
  margin: 10;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding: 5px;
  margin: 5px;
  font-size: 15px;
}

.item p:hover {
  background-color: #007bff;
  color: #fff;
}
.useroptions-dropdown {
  position: absolute;
  top: 60px;
  left: 1125px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 7px;
}

.useroptions-option1 {
  padding: 10px 50px 10px 2.2px;
}
.useroptions-option2 {
  padding: 10px 50px 10px 14px;
}
.useroptions-option1:hover {
  background-color: #3c7bd4;
  color: white;
}

.useroptions-option2:hover {
  background-color: #3c7bd4;
  color: white;
}