.details-container {
  display: flex;
}

.left-section {
  width: 30%;
  padding: 10px;
}

.line {
  border-left: 1px solid #000;
  height: 100%;
  margin: 0 10px;
}

.right-section {
  flex: 1;
  padding: 10px;
}

.left-users {
  width: 24%;
  border-top-left-radius: 13px !important;
  border-bottom-left-radius: 13px !important;
  background-color: #fffdfd;
  overflow-y: auto;
}

.users-row {
  padding: 14px;
}

.users-row:hover {
  color: white;
  cursor: pointer;
}
.name {
  font-weight: 500;
}

.status{
    margin-top: 4px;
}

.useremail{
    margin-top: 2px;
}

.right-users{
    flex: 1; /* Take remaining width */
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.modal-body {
  scrollbar-width: thin;
  scrollbar-color: #b0bec5 #f5f5f5; /* You can adjust the colors as needed */
}

.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #b0bec5;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

  .password-input {
    position: relative;
  }
  
  .password-input .toggle-password-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
  }
  