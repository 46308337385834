.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container {
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.react-datepicker__view-calendar-icon input {
  padding: .375rem .75rem;
}
.customer-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.customer-flex-item {
  flex: 1 1;
  padding: 10px;
}

.customer-subtotal-box {
  padding: 20px;
  border-radius: 7px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background: #fff;
}
.cnotes-textarea {
  width: 100% !important;
  background-color: rgb(246, 246, 246) !important;
  overflow: hidden;
}
.invoicetextarea {
  overflow: hidden;
  width: 100% !important;
  background-color: rgb(246, 246, 246) !important;
}

/****Popup*****/

.customer-popup-overlay {
  position: fixed;
  top: 0;
  left: 100px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}
.customer-popup-content {
  width: 600px;
  background-color: white;
  padding: 15px !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
  z-index: 3;
  max-width: 80%;
  height: auto;
}
.popupinvoice-container {
  padding: 10px;
}

.customer-headline-label {
  top: 14px;
  left: 15px;
  font-size: 14px;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.headline {
  font-size: 15px;
  font-weight: 400;
}
.customer-option {
  font-weight: 500;
  color: rgb(33, 37, 41);
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
}
.customer-rdiobox-label {
  top: 14px;
  left: 15px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-family: "Avenir Next";
  font-weight: 500;
}

.customer-generate-inputfield {
  width: 140px;
}

.customer-generate-inputfield2 {
  width: 210px;
  margin-left: 10px;
}

.salesperson-btn {
  border: 1.4px solid #c1bebe !important;
  border-radius: 10px;
  background-color: #fff;
  color: #555555;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.invoice-bottombtns {
  margin-top: 40px;
  margin-bottom: 10px;
}

/***** New Customer Modal *****/

.modalinputchild {
  margin-left: 45px;
}

.modalinputchild input[type="text"],
input[type="date"],
textarea {
  background-color: rgb(245, 245, 245);
  border-radius: 9px;
  margin-top: 10px;
  font-size: 13px;
  height: 35px;
  width: 200px !important;
}

.modalinputchild select {
  width: 200px !important;
}

/***** Sales Person Modal *****/

.modalsalesinput {
  margin-left: 45px;
}

.modalsalesinput input[type="text"] {
  background-color: rgb(245, 245, 245);
  border-radius: 9px;
  margin-top: 5px;
  font-size: 13px;
  height: 35px;
  width: 400px !important;
}

.modalbtn-3 {
  display: inline-flex;
  height: 40px;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid var(--Primary, #3c7bd4) !important;
  background: var(--Primary, #3c7bd4);
}

.modalbtn-1 {
  display: inline-flex;
  height: 40px;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-right: 10px;
  margin-top: 5px;
  flex-shrink: 0;
  border-radius: 7px;
  border-radius: 7px;
  color: #3c7bd4;
  border: 1px solid var(--Primary, #3c7bd4) !important;
  background: var(--Card-color, #fff);
}

.modal-bottombtns {
  margin-top: 5px;
  margin-bottom: 5px;
}
