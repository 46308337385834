/* Add this to your CSS file or style section */
et .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .loader {
    border-top: 8px solid #3437db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
 
  .loginhead {
    color: var(--Black-main-text, rgba(0, 0, 0, 0.8));
      margin-top: 35px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .inputbox input[type="email"] {
    flex-shrink: 0;
    border-radius: 7px;
    flex: none;
    background: #f6f6f6;
  }
  .inputbox1 input[type="email"] {
    flex-shrink: 0;
    flex: none;
    border-radius: 7px;
    background: #f6f6f6;
  }
  
  .passwordbox input[type="password"] {
    flex: none;
    flex-shrink: 0;
    border-radius: 9px;
    background: #f6f6f6;
  }
  .text2 {
    color: var(--Black-medium-text, rgba(0, 0, 0, 0.5));
    font-size: 12px;
    font-style: normal;
    margin-top: 20px;
    font-weight: 500;
    line-height: normal;
  }
  .text1 {
    color: var(--Black-medium-text, rgba(0, 0, 0, 0.5));
    font-size: 12px;
    font-style: normal;
    line-height: normal;
  }
  .emailbox {
    margin-top: 45px;
  }
  .forgetbtn {
    width: 92%;
    display: flex;
    justify-content: flex-end;
  }
  .signInbtn {
    display: flex;
    width: 92%;
    margin-top: 0 !important;
    height: 43px;
    padding: 5px 42px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #fff;
    border-radius: 7px;
    background: var(--Primary, #3c7bd4);
  }
  .orline {
    margin-top: 30px !important;
  }
  
  .socialsignbtn {
    width: 205px;
    margin-top: 56px;
    height: 46px;
    padding: 15px 29px 15px 27px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: none !important;
    border-radius: 7px;
    border: 1px solid var(--Primary, #3c7bd4) !important;
    background: #ffffff !important;
  }
  .bottom {
    margin-right: 40%;
  }
  .btnsignuptext {
    color: var(--Black-medium-text, rgba(0, 0, 0, 0.5)) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 1% !important;
  }
  .rigthpart {
    left: 50%;
  }
  .auth-main {
    background: var(--Card-color, #fff) !important;
    box-shadow: 0px 0px 14px 6px rgba(0, 0, 0, 0.1) !important;
    width: 50vw !important;
    margin: 20px !important;
    padding: 12px !important;
  }
  .leadtext {
    margin-top: 8px;
    color: var(--Black-medium-text, rgba(0, 0, 0, 0.5));
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .signupfirstline {
    margin-top: 30px;
    width: 100%;
  }

  .signupsecondrow,.signupthirdrow{
    width: 100%;
  }
  .ckbox{
    width: 92%;
    display: flex;
    align-items: center;
  }
  input[type="text"],
  input[type="email"],
  input[type="type"],
  input[type="password"] {
    flex-shrink: 0;
    border-radius: 7px;
    background: #f6f6f6;
  }
  .signuptext {
    color: var(--Black-medium-text, rgba(0, 0, 0, 0.5));
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .orline {
    margin-right: 10px !important;
  }
  
  .singlerow {
    margin-top: 20px;
  }
  .orline {
    width: 92% !important;
  }
  .registertext {
    color: var(--Black-main-text, rgba(0, 0, 0, 0.8));
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .dataformat {
    display: flex;
  }
  .threebutton {
    text-align: center;
  }
  .threebutton button {
    margin: 5px;
    padding: 2px;
    width: 12px;
    height: 12px;
    border: 1px solid rgb(54, 23, 193);
    border-radius: 50%;
    background-color: #fff;
    transition: 0.5s;
  }
  
  .threebutton button:active:focus {
    width: 30px;
    background-color: #3c7bd4;
    border-radius: 0;
    border: none;
    outline: nont;
  }    
  .rightpartofsingin {
    height: 100%;
    background-image: url("../../asset/images/newImages/abstract1.png");
    background-size: cover;
  }

  .carousel-indicators{
    display: none;
  }
  
  .signupscrollview {
    overflow-y: none;
    scroll-behavior: smooth;
    overflow: hidden;
    height: 50vh;
    scrollbar-width: 10px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .hiddendata {
    overflow: hidden;
  }
  .signuphiddendata {
    left: 0 !important;
    overflow-y: scroll;
    width: 100% !important;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    height: 87vh;
  }
  
  .signuphiddendata:-webkit-scrollbar {
    display: none;
  }
  
  .scrollofthesignupage {
    scrollbar-width: none;
    overflow: hidden;
    background-color: #000 !important;
    height: 100vh;
    scrollbar-width: 2px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .scrollofthesignupage::-webkit-scrollbar {
    display: none;
  }
  .wrapper {
    overflow: hidden !important;
  }
  .forgetcomponent {
    margin: 20px;
    padding: 20px;
  }
  .forgetcomponent .heading {
    color: #40c4ae;
  }
  .datacentr {
    margin-right: 30%;
  }