.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container .customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 100%;
}
.text-style {
  font-size: 12px;
  color: var(--Black-medium-text, rgba(0, 0, 0, 0.5));
  font-size: 14px;
  color: grey;
  font-weight: 600;
  margin-top: 15px;
}

.estimate-subtotal-box {
  padding: 20px;
  border-radius: 7px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background: #fff;
}

.tc-textarea {
  width: 100% !important;
  border-radius: 7px;
  background-color: rgb(246, 246, 246) !important;
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.tc-textarea:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--primary-color);
}

.tc-textarea:hover {
  border-color: #2c93ff !important;
}

.subjectbox > input {
  background-color: #f6f6f6;
  border-radius: 7px;
  padding: 12px;
  height: 46px !important;
  margin-top: 10px !important;
}

.subject-textarea::placeholder {
  color: var(--Black-light-text, rgba(0, 0, 0, 0.25));
}

.cn-textarea {
  width: 100% !important;
  border-radius: 7px;
  background-color: rgb(246, 246, 246) !important;
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cn-textarea:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--primary-color);
}

.cn-textarea:hover {
  border-color: #2c93ff !important;
}

.addline-btn {
  border: 1.4px solid #c1bebe !important;
  border-radius: 7px;
  color: #555555;
}

.salesperson-btn {
  border: 1.4px solid #c1bebe !important;
  border-radius: 7px;
  background-color: #fff;
  color: #555555;
}

.estimateinputs {
  margin-left: 19px;
}
.estimateinputs input[type="text"],
textarea {
  background-color: rgb(245, 245, 245);
  border-radius: 7px;
  margin-top: 10px;
  font-size: 13px;
  height: 40px;
  width: 250px !important;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 100px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}
.popup-content {
  background-color: white;
  padding: 15px !important;
  border-radius: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
  z-index: 3;
  max-width: 80%;
  height: auto;
}
.headline-label {
  top: 14px;
  left: 15px;
  color: #000000a6;
  font-size: 14px;
  font-family: "Avenir Next";
  font-weight: 500;
}

.rdiobox-label {
  top: 14px;
  left: 15px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-family: "Avenir Next";
  font-weight: 500;
}

.generate-inputfield {
  width: 110px;
}

.generate-inputfield2 {
  width: 110px;
  margin-left: 10px;
}
