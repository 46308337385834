.recurring-templates-container {
  display: flex;
  height: 560px;
}

.left-reucrringinvoice {
  width: 38%;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  background-color: #fffdfd;
  overflow-y: auto;
}

.recurringinvoice-row {
  padding: 15px;
}

.recurringinvoice-row:hover {
  background-color: #eceff3;
}

.float-end {
  float: right;
  font-size: 13px;
}

.list-primary .amount {
  font-size: 14px;
  text-align: right;
}

.list-primary .recurringnum {
  font-size: 14px;
  max-width: 300px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-secondary {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 400;
}

.list-notes {
  margin-top: 3px;
}

.list-notes .note {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  margin-left: 0;
}

.status {
  font-size: 12px;
}

/**************Right side content ***************/
.right-recurringinvoice {
  padding: 0 !important;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.top-heading {
  margin: 15px 15px 0px 15px;
}

.overview-container {
  min-height: 100%;
  display: flex;
  align-items: stretch;
  height: 460px;
}

.details-info {
  border-right: 1px solid #eee;
  background-color: #fbfbfb;
  width: 34%;
  vertical-align: top;
  padding: 10px 20px 70px;
  word-wrap: break-word;
}

.overview-info {
  display: flex;
  margin: -10px -20px 40px;
  background-color: #fbfafe;
}

.group1 {
  padding-top: 15px;
  margin-bottom: 10px;
}

.group2 {
  display: grid;
  grid-row-gap: 8px;
  padding-top: 16px;
  overflow-wrap: anywhere;
}

.details-info2 {
  flex: 0 0 68%;
  padding: 10px 20px 10px;
}

.nextinvoice-container {
  padding: 13px;
}

.recentactivities-container {
  margin-left: 50px;
  margin-top: 50px;
}

.recents-list {
  margin-left: 100px;
  border-left: 1px solid #277ad8;
}

.tree-node {
  padding: 0px 0px 10px 0;
  position: relative;
  margin-left: 15px;
}

.tree-node::before {
  content: " ";
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid #1b6ac4;
  border-radius: 50%;
  top: 30px;
  left: -5.3px;
  background-color: #277ad8;
}

.time {
  position: absolute;
  top: 25px;
  left: -120px;
  width: 90px;
  text-align: right;
}

.name {
  font-size: 14px;
  max-width: 300px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tree-node-leaf {
  padding: 10px 20px;
  position: relative;
  margin-bottom: 10px;
  margin-left: 25px;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 6px;
}

.description {
  margin-bottom: 5px;
}

.recurringinvoices-table{
  width: 100%;
}
