.popup-container {
    text-align: center;
    margin-top: 100px;
  }
  
  .open-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Change this to align content at the top */
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin-top: 50px; /* Add margin to push content down */
  }
  
  .close-button {
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 14px;
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
  }
  .text-ellipse{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
 }
 /* MyComponent.css */
.my-component {
  text-align: center;
  position: relative;
}

.popup-button {
  background-color: #1abc9c;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align content to the top */
  z-index: 2; /* Higher z-index for the overlay */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 80px; /* Add margin to push the content down */
  z-index: 3; /* Higher z-index for the content */
  max-width: 80%;
  width: flex;
  height: auto;
}
.popup-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 500px; 
  margin-top: 80px; /* Add margin to push the content down */
  z-index: 3; /* Higher z-index for the content */
  max-width: 80%;
  height: auto;
}

.close-button {
  background-color: #25abff;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-top: 0px;
}

.close-button:hover {
  background-color: #25abff;
}

.text{
  text-align: left;
}

.headline{
  margin-top: 0;
}
.label {
  font-size: 16px; /* Adjust the font size as needed */
}
.popup-container input[type="radio"] {
  transform: scale(1.5); /* Increase the scale factor as needed */
}

.container-div {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

/* Styling for the left and right divs */
.left-div, .right-div {
  padding: 5px;
  border: 0;
}
.left-div {
  flex: 1;
}

/* Styling for the right div */
.right-div {
  flex: 1;
  text-align: left;
}
.cl{
  color:red;
}

  