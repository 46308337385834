/* HorizontalProfit.css */

.horizontal-profit-row {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }
  
 /* Add these styles to Horizontal.css */
.left-section {
    text-align: left;
  }
  
  .left-section-content {
    display: flex;
    justify-content: space-between;
  }
  
  .left-section-content p {
    margin: 0;
  }
/* Add this to your existing CSS file or create a new one */

/* Style for the right section */
.right-section {
    text-align: left;
  }
  
  /* Style for the right section content */
  .right-section-content {
    display: flex;
    justify-content: space-between;
  }
  
  .right-section-content p {
    margin: 0;
  }

/* *********MY STYLES*********** */

.horizontal-section{
  padding: 30px;
  background-color: #fff;
}

.head-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table-section{
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
}

.table-heading{
  font-size: 15px;
  font-weight: 600;
}

.expenses-content{
    padding: 15px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.income-content{
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.table-body-left{
  padding: 15px;
  padding-bottom: 20px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.table-body-right{
  border-bottom: 1px solid #ddd;
  padding: 15px;
  padding-bottom: 20px;
}

.total-1{
  border-right: 1px solid #ddd;
}

.total-1,.total-2{
  padding: 15px;
}

.inner-container,.content p{
  font-size: 12px;
}

.table-subtitle{
  font-size: 16px;
  font-weight: 600;
}

 
  
 
  