/* Templates.css */
.custom-templates-container {
  display: flex;
  height: auto;
}

.left-invoice {
  width: 17%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fffdfd;
  overflow-y: auto;
  max-height: 120vh;
}
.invoice-row {
  padding: 10px;
}
.invoice-row:hover {
  background-color: #eceff3;
  color: #3C7BD4;
}
.right-invoice {
  flex: 1; /* Take remaining width */
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.bill {
  background-color: rgb(236, 239, 243);
  height: 40px;
}

/* Templates.css */
.invoice-border {
  border: 4px solid;
}
.section {
  margin-top: 10px;
}
.section1 {
  border-radius: 8px;
  margin-top: 0px;
  flex: 1; /* Take remaining height */
}
.invoice-data {
  justify-content: center;
  align-items: center;
  width: auto;
}
.vertical-line {
  border-left: 1px solid #000;
}

.middle-line {
  border-left: 1px solid #d0caca;
  height: auto;
}

.horizontal-line {
  border-top: 1px solid #d2cdcd;
  width: auto;
  margin: auto;
}

.payment-gateway {
  width: 90%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 12px;
}

.payment-gateway .top-bar-icons {
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  position: relative;
}

.payment-gateway .top-bar-icons.active {
  color: #007bff; /* You can replace this with your desired active text color */
}

.payment-gateway .top-bar-icons.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* You can adjust the thickness of the underline */
  background-color: #007bff; /* You can replace this with your desired active underline color */
}

.payment-gateway .top-bar-icons:hover {
  background-color: #e0e0e0; /* You can replace this with your desired hover background color */
}

.print-icon {
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.payment-gateway button {
  background-color: #28a745; /* Green background color for the button */
  color: #ffffff; /* Text color for the button */
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.print-section {
  border: 1px solid #e0e0e0; /* Border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
}

.statment-navbar {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 12px;
}

.statment-navbar .top-bar-icons {
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  position: relative;
}

.statment-navbar .top-bar-icons.active {
  color: #007bff; /* You can replace this with your desired active text color */
}

.statment-navbar .top-bar-icons.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* You can adjust the thickness of the underline */
  background-color: #007bff; /* You can replace this with your desired active underline color */
}

.statment-navbar .top-bar-icons:hover {
  background-color: #e0e0e0; /* You can replace this with your desired hover background color */
}

/* Your CSS file */
.invoicedetail-data {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.invoicedetail-data .bill {
  margin: 10px 0;
}

.invoicedetail-data table {
  width: 100%;
}

.invoicedetail-data table th {
  background-color: #f0f0f0;
  font-weight: bold;
  text-align: center;
}

.invoicedetail-data table td {
  text-align: center;
}

.overview-content {
  display: flex;
}

.first-col {
  flex: 0 0 30%; /* This sets the first column to be 30% of the container */
  padding: 10px; /* Add padding as needed */
}

.second-col {
  flex: 0 0 70%; /* This sets the second column to be 70% of the container */
  padding: 10px; /* Add padding as needed */
}

.accordion-container {
  width: 100%;
}

.accordion-item {
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  background-color: #f9f9f9;
  user-select: none;
}

.accordion-title.active {
  background-color: #e5e5e5;
}

.accordion-content {
  padding: 12px;
  background-color: #fff;
}

.row-container {
  display: flex;
}

.column {
  flex: 1;
  padding: 5px;
}

.accordion-container {
  flex: 2;
  padding: 10px;
}

.col-left {
  flex: 0 0 20%;
  padding: 0px;
  margin: 0;
}

.col-right {
  flex: 0 0 80%;
  padding: 0px;
  margin: 0;
}

/* ----- */
.inner-cols-container {
  display: flex;
}

.inner-col {
  flex: 1;
  margin-right: 10px;
}

.edithover:hover {
  color: rgb(42, 153, 163);
  text-decoration: underline;
}
/* comments card */
.custom-card {
  width: 700px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-card-body {
  padding: 20px;
}

.customer-card {
  width: 100%;
  margin: 20px;
  border: 1px solid #ccc;   
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customer-card-body {
  padding: 20px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #8eb6ee #f5f5f5; /* You can adjust the colors as needed */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b0bec5;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.back-icon:hover {
  color: red; /* Change the color to red on hover */
}

/********Tax Invoice********/
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --blue-color: #0c2f54;
  --dark-color: #535b61;
  --white-color: #fff;
}

ul {
  list-style-type: none;
  margin-bottom: 0px;
}
ul li {
  margin: 2px 0;
}

/* text colors */
.text-dark {
  color: var(--dark-color);
}
.text-blue {
  color: var(--blue-color);
}
.text-end {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-start {
  text-align: left;
}
.text-bold {
  font-weight: 700;
}
/* hr line */
.hr {
  height: 1px;
  background-color: black;
}
/* border-bottom */
.border-bottom {
  border-bottom: 1px solid #9e9e9e;
}
.right-border {
  border-right: 1px solid #9e9e9e;
}
body {
  font-family: "Poppins", sans-serif;
  color: var(--dark-color);
  font-size: 14px;
}
.invoice-wrapper {
  width: 930px;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
}
.invoice-container{
  border: 1px solid #9e9e9e
}
.invoice {
  width: 930px;
  margin-right: auto;
  margin-left: auto;
  background-color: var(--white-color);
  padding: 70px;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  min-height: 830px;
}

.invoice-head-top-left img {
  width: 130px;
}
.invoice-head-top-right h3 {
  font-weight: 500;
  font-size: 27px;
  color: var(--blue-color);
}
.invoice-head-middle,
.invoice-head-bottom {
  padding: 10px 0;
}
.invoice-body {
  border-radius: 4px;
  overflow: hidden;
}
.invoice-body table {
  border-collapse: collapse;
  border-radius: 4px;
  width: 100%;
}
.invoice-body table td,
.invoice-body table th {
  padding: 12px;
  border-left: 1px solid #9e9e9e;
}
.invoice-body table tr {
  border-bottom: 1px solid #9e9e9e;
}
.invoice-body table thead {
  background-color: #f2f3f4;
}
.invoice-body-info-item {
  display: grid;
  grid-template-columns: 80% 20%;
}
.invoice-body-info-item .info-item-td {
  padding: 12px;
}
.invoice-foot {
  padding: 30px 0;
}
.invoice-foot p {
  font-size: 12px;
}
.invoice-btns {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.invoice-btn {
  padding: 3px 9px;
  color: var(--dark-color);
  font-family: inherit;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.left-border {
  border-left: 1px solid #9e9e9e;
}
.invoice-head-top,
.invoice-head-middle,
.invoice-head-bottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 992px) {
  .invoice {
    padding: 40px;
  }
}

@media screen and (max-width: 576px) {
  .invoice-head-top,
  .invoice-head-middle,
  .invoice-head-bottom {
    grid-template-columns: repeat(1, 1fr);
  }
  .invoice-head-bottom-right {
    margin-top: 8px;
    margin-bottom: 12px;
  }
  .invoice * {
    text-align: left;
  }
  .invoice {
    padding: 28px;
  }
}

.invoice-body {
  min-width: 600px;
}

@media print {
  .print-area {
    visibility: visible;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
  }

  .overflow-view {
    overflow-x: hidden;
  }

  .invoice-btns {
    display: none;
  }
}
.mail-open{
  background-color: white !important;
  margin-top: 18px!important;
  width: 100% !important;
  padding: 3% !important;
}

.offline-page{
  width: 50%!important;
}