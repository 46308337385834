* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.first {
  display: flex;
  margin-top: 10px;
}

.child-1 {
  display: flex;
  margin-left: 2px;
  padding: 10px;
  border-radius: 10px;
  width: 270px !important;
  height: 50px;
}

.child-3 {
  display: flex;
  margin-left: 2px;
  padding: 10px;
  border-radius: 10px;
  width: 250px;
  height: 58px;
}

.child-2 {
  margin-top: 0px;
  margin-left: 0px;
  width: 270px !important;
  height: 50px;
  color: gray;
  background-color: #f5f5f5 !important;
}

input[type="text"],
textarea,
select {
  background-color: #f5f5f5}
select {
  background: #f5f5f5;
}

input[type="radio"] {
  border-radius: 50%;
  width: 13px;
  height: 13px;
}

.rdiobtn {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.bgcolor {
  background-color: "#f5f5f5";
}

.text1 {
  font-size: 14px;
  color: gray;
  font-style: normal;
}

.heading-text {
  font-style: normal;
}
.input-group-text {
  border:none /* Adjust color and thickness as needed */
}

.input-text {
  color: black;
  font-size: 14;
  word-wrap: break-word;
  border:none
}

.head {
  color: var(--Primary, #3c7bd4);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 30px;
}

.btn1 {
  display: inline-flex;
  height: 46px;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid var(--Primary, #3c7bd4);
  background: var(--Primary, #3c7bd4);
}

.btn1cancel {
  display: inline-flex;
  height: 46px;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #3c7bd4;
  flex-shrink: 0;
  border-radius: 7px;
  border-radius: 7px;
  border: 1px solid var(--Primary, #3c7bd4) !important;
  background: var(--Card-color, #fff);
}

.navpart {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
  justify-self: center;
  margin-top: 8px;
  margin-left: 5px;
  padding: 5px;
}

.arrow {
  margin: 3px;
  color: gray;
  width: 35px;
  height: 35px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: #fff;
  border: 0px;
}

.arrow-button {
  margin: 0px;
  color: gray;
  width: 35px;
  height: 35px;
  margin-left: 0px;
  border-radius: 50%;
  background-color: #fff;
  border: 0px;
}
.arrow-button1 {
  margin: 0px;
  color: rgb(131, 130, 130);
  width: 20px;
  height: 20px;
  margin-left: 0px;
  border-radius: 50%;
  text-align: center;
  background-color: #dfdcdc;
  border: 0px;
}

.arrow-child {
  margin: 6px;
  padding: 6px;
}

.createitemitext {
  color: var(--Primary, #3c7bd4);
  font-size: 20px;
  margin-top: 10px;
  margin-left: 8px;
}

.new-button {
  border-radius: 7px !important;
  width: 120px !important;
  height: 40px !important;
}
.new-purchase{
  border-radius: 7px !important;
  width: 160px !important;
  height: 40px !important;
  text-align: center !important;
}
.icon-dot{
  background-color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
}