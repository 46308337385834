.drag-drop-input {
    width: 200px;
    height: 150px;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .drag-drop-input.dragging {
    border-color: #007bff;
  }
  
  .instructions {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .or {
    font-size: 14px;
    margin-bottom: 5px;
    color: #888;
  }
  
  .file-input-label {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .file-input-label:hover {
    background-color: #0056b3;
  }
  