.bankaccinputchild {
  margin-left: 19px;
}
.bankaccinputchild input[type="text"] {
  background-color: rgb(245, 245, 245);
  border-radius: 9px;
  margin-top: 10px;
  font-size: 13px;
  height: 50px;
  width: 330px !important;
}


.description-textarea {
  border-radius: 7px;
  width: 49% !important;
  background-color: rgb(246, 246, 246) !important;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-3 {
  display: inline-flex;
  height: 46px;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid var(--Primary, #3c7bd4);
  background: var(--Primary, #3c7bd4);
}
.btn-1 {
  display: inline-flex;
  height: 46px;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #3c7bd4;
  flex-shrink: 0;
  border-radius: 7px;
  border-radius: 7px;
  border: 1px solid var(--Primary, #3c7bd4) !important;
  background: var(--Card-color, #fff);
}

.bottom-buttons {
  margin-top: 40px;
  margin-right: 32px;
  margin-bottom: 30px;
}
