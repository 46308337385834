/* / Templates.css / */
.templates-container1 {
    display: flex;
    height: auto;
   }
  
  .temp{
    height: auto;
  }

  .left-expenses {
    width: 20%;
    background-color: #fffdfd;
    height: auto;
  }
  .right-expenses {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .bill{
    background-color: rgb(236, 239, 243);
    height: 40px;
  }
  
  .top-template-bar {
    background-color: rgb(60, 61, 62);
    color: #fff;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    height: 30%;
  }
  .top-template-bar1 {
    background-color: rgb(236, 239, 243);
    color: #100404;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

.expense-border {
  border: 4px solid; 
  height: auto;
}

  .section {
    margin-top:10px;
  }
  .section1 {
    margin-top:0px;
    flex: 1;
  }
  .hello {
    justify-content: center;
  align-items: center;
  width: 100%;
  }
  .vertical-line {
    border-left: 1px solid #000; 
  }
  
  .payment-gateway{
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin: 10px; 
  }

  .payment-gateway  .top-bar-icons {
    display: flex;
    align-items: center;
  }
  
  .payment-gateway button {
    background-color: #28a745; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }

  /* / Your CSS file / */
.invoice-data {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
  height: auto;
}

.invoice-data .bill {
  margin: 10px 0;
}

.invoice-data table {
  width: 100%;
}

.invoice-data table th {
  background-color: #f0f0f0;
  font-weight: bold;
  text-align: center;
}

.invoice-data table td {
  text-align: center;
}
/* addedd by akash */
.horizontal-line {
  border-top: 1px solid #d0caca;
  width: 100%; /* If you want the line to span the full width */
}
.expense-row{
  height: 40px;
  padding-top: 5px;
}

.expense-row:hover {
  background-color: #ECEFF3; 
  color:#28a745;
}
