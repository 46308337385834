@media print {
    @page {
      size: landscape;
      margin: 0.5in; /* Adjust this value if needed */
    }
  
    .content-to-print {
      width: 100%;
      overflow: hidden;
      margin: 0; /* Ensure no extra margins */
      padding: 0; /* Ensure no extra padding */
      box-sizing: border-box; /* Include padding and border in element's total width and height */
    }
  
    .print-table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      font-size: 10px; /* Adjust font size to fit content */
      transform: scale(0.9); /* Adjust scaling if necessary */
      transform-origin: top left; /* Ensure scaling starts from the top left */
    }
  
    .print-table th, .print-table td {
      border: 1px solid #000;
      text-align: left;
      padding: 4px; /* Reduce padding to fit content */
      white-space: nowrap;
    }
  
    .print-table thead {
      display: table-header-group;
    }
  
    .print-table tfoot {
      display: table-footer-group;
    }
  }
  