

.dashboard-card {
  width: 800px;
  margin: 20px;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  
}
.additional-row
{
  text-align: left;
}
.additional-row1{
 
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
  padding: 15px;
  background-color: #f9f9fb;
}

.new-btn{ 
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;

}

.addtional-row2{
  
  border-bottom: 1px solid lightgray;
  
}

.addtional-row33{
  padding: 12px;
  display: flex;
  text-align: left;
  justify-content: center; 
  
  

}

.addtional-row3{
  padding: 12px;
  display: flex;
  text-align: left;
  justify-content: center; 
  
  

}
.hover-highlight:hover {
  background-color: #f3f4f6;
  margin: 2px;
  border-radius: 5px;
  color: #007bff;
}
.dropdown-arrow {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
}

.count-card {
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: "Calibri", sans-serif;
  color: black;
  border-radius: 7px !important;
}

.dashboard-card-body {
  padding: 20px;
}

.current-column {
  border-right: 2px solid lightgray; /* Creates the vertical border */
  padding-right: 20px; /* Adds spacing before the border */
}

.vertical-line {
  width: 2px;
  /* background-color: black;  */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.current-column2 {
  margin-top: auto;
  margin-bottom: 150px;
  border-left: 1px solid #a5a0a0;
  height: 300px;
}

/* tab card */
.tab-card {
  width: 800px;
  border: 1px solid #ccc;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.tab-card-body {
  padding: 20px;
}

.body-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.body-row:hover {
  background-color: #f0ffff;
}

/* dropdown */

.dropdown-table {
  width: 100%;
  border-collapse: collapse;
}

.top-bar-icons {
  position: relative;
  z-index: 1000;
}

.card-container {
  position: absolute;
  top: 80%;
  left: 18%;
  width: 12%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

/* hover row */
.hover-row:hover {
  background-color: #e0e0e0;
  width: 132px;
}

/* -- */
.custom-dropdown-menu {
  position: absolute;
  z-index: 999;
}

/* In your CSS file or style section */
.new-dropdown-toggle {
  border: none; /* Remove the border */
  outline: none; /* Remove the outline */
  box-shadow: none; /* Remove any box shadow */
}

/* In your CSS file or style section */
.new-dropdown-toggle {
  border: none; /* Remove the border */
  outline: none; /* Remove the outline */
  box-shadow: none; /* Remove any box shadow */
}

.custom-progress-bar {
  display: flex;
  overflow: hidden;
  height: 20px;
  background-color: #eee; /* Light gray background */
  border-radius: 1px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.progress-bar {
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 12px;
  transition: width 0.1s ease; /* Smooth width transition */
}

.progress-bar-success {
  background-color: #28a745; /* Green background for success */

}

.progress-bar-danger {
  background-color: #dc3545; /* Red background for danger */
}
.dropdown-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.dropdown {
  padding: 10px;
  font-size: 12px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: #333;
  cursor: pointer;
  width: 160px; /* Adjust width as needed */
  height: 40px;
}

.dropdown:hover {
  background-color: #d9d9d9;
}

.dropdown:focus {
  outline: none;
  border-color: dodgerblue;
  box-shadow: 0 0 5px dodgerblue;
}

/* Style for selected option */
.selected-option {
  font-weight: bold;
  color: dodgerblue;
}
.cardhead {
  width: "48%";
  height: "180px";
  background-color: "white";
}
.card-body {
  width: 100%;
  /* background-color: red; */
}

.childcard {
  width: "260px";
  height: "110px";
}


.head-dash{
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  /* padding: 0 -50px; */
  /* padding: 0 50px; */
  /* background-color: #fff; */
  border-bottom: 1px solid lightgray;
}

.dash-row-head{
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* background: #000; */
}

.dash-btn{
  margin-top: 0px;
  
  /* background: #000; */
}

.dash-button{
  width: 100px;
  height: 30px;
  /* background-color: #007bff; */
  /* color: #fff; */
  border: none;
  outline: none;
  font-size: 18px;
  border-bottom: 2px solid #007bff;
  /* border-radius: 5px; */
}


.head-box1{
  width: 300px;
  /* background-color: #888; */
}

.head-box2{
  width: 320px;
  /* background-color: #888; */
  font-size: 14px;
  text-align: right;
  line-height: 20px;
}

.head-box2 p{
  font-size: 10px;
}

/* .dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}


.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 0;
  margin-top: 5px;
  list-style: none;
  display: none;
}

.plus-logo{
border-radius: 50%;
background-color: #007bff;
color: #fff;
width: 40px;
padding: 2px 5px;
font-size: 14px;
}


.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 0;
  margin: 0 8px;
}

.dropdown-menu li:hover {
  background-color: #007bff;
  color: #fff;
}

.dropdown-menu li:hover {
  .plus-logo{
    background: #fff;
    color: #007bff;
    font-weight: bold;
  }
} */
/* .text-left{
  text-align: left;
} */