
.templates-mcontaine {
  display: flex;
  height: 80vh;
}

.left-customer {
  width: 19%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #fffdfd;
  overflow-y: auto;
}
.customer-row {
  padding: 10px;
}

.customer-row:hover {
  background-color: #eceff3;
  color: #28a745;
}

.bill {
  background-color: rgb(236, 239, 243);
  height: 40px;
}

.increased-border {
  border: 4px solid;
  height: 1100px; /* Adjust the height as needed */
}
.section {
  margin-top: 10px;
}
.section1 {
  width: 100% !important;
  margin-top: 0px;
  flex: 1; /* Take remaining height */
}
.hello {
  justify-content: center;
  align-items: center;
  width: 100%;
}
.vertical-line {
  border-left: 1px solid #d9d9d9 !important;
}

.middle-line {
  border-left: 1px solid #d0caca;
  height: auto;
}

.horizontal-line {
  border-top: 1px solid #d0caca;
}

.horizontal1-line {
  border-top: 1px solid #d0caca;
  width: auto;
}

.payment-gateway {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 12px;
}


.payment-gateway .top-bar-icons {
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  position: relative;
}

.payment-gateway .top-bar-icons.active {
  color: #007bff; /* You can replace this with your desired active text color */
}

.payment-gateway .top-bar-icons.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; 
}

.payment-gateway .top-bar-icons:hover {
  background-color: #e0e0e0; /* You can replace this with your desired hover background color */
}

.print-icon {
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.payment-gateway button {
  background-color: #28a745; 
  color: #ffffff; 
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.print-section {
  border: 1px solid #e0e0e0; /* Border */

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
}

.statment-navbar {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 12px;
}

.statment-navbar .top-bar-icons {
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  position: relative;
}

.statment-navbar .top-bar-icons.active {
  color: #007bff; /* You can replace this with your desired active text color */
}

.statment-navbar .top-bar-icons.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* You can adjust the thickness of the underline */
  background-color: #007bff; /* You can replace this with your desired active underline color */
}

.statment-navbar .top-bar-icons:hover {
  background-color: #e0e0e0; /* You can replace this with your desired hover background color */
}

.invoice-data {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.invoice-data .bill {
  margin: 10px 0;
}

.invoice-data table {
  width: 100%;
}

.invoice-data table th {
  background-color: #f0f0f0;
  font-weight: bold;
  text-align: center;
}

.invoice-data table td {
  text-align: center;
}

.overview-content {
  display: flex;
}

.first-col {
  flex: 0 0 35%; /* This sets the first column to be 30% of the mcontaine */
  padding: 0px !important;
}

.second-col {
  flex: 0 0 68%; /* This sets the second column to be 70% of the mcontaine */
  padding: 2px !important;
}

.accordion-item {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0px !important;
}

.custom-accordion-title {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 15px 0px;
  cursor: pointer;
  background-color: #fff;
  user-select: none;
}

.accordion-content {
  padding: 2px;
  background-color: #fff;
}

.row-mcontaine {
  display: flex;
}

.column {
  flex: 1;
  padding: 5px;
}

.accordion-mcontaine {
  flex: 2;
  padding: 1px 1px 1px 0px;
}

.col-left {
  flex: 0 0 20%;
  padding: 0px;
  margin: 0;
}

.col-right {
  flex: 0 0 80%;
  padding: 0px;
  margin: 0;
}

.inner-cols-mcontaine {
  display: flex;
}

.inner-col {
  flex: 1;
  margin-right: 0px !important;
}

.edithover:hover {
  color: rgb(42, 153, 163);
  text-decoration: underline;
}

.custom-card {
  overflow-y: auto;
  width: 700px;
  margin: 20px;
}
.textarea-customer {
  width: 100%;
}
.custom-card-body {
  padding: 5px;
}

.customer-card-body {
  padding: 20px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #b0bec5 #f5f5f5; /* You can adjust the colors as needed */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b0bec5;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.mcontaine {
  margin: 10px;
  padding: 10px;
  width: 1117px !important;
  height: 553px !important;
  flex-shrink: 0;
  border-radius: 7px;
}
.mcontaine .card {
  width: 1187px !important;
}
.tab-content {
  padding: 0px !important;
}
.withoutbtn {
  border: none !important;
  outline: none !important;
  background-color: #fff !important;
  color: #00000091 !important;
}
.withoutbtn:hover{
  color: #212529 !important;
  text-decoration: none;
}
.listitem {
  margin: 0 15pxiner;
}
.cardm .body {
  padding: 0px !important;
}
.right-content1 {
  padding: 0 !important;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.unusedcredits {
  text-align: center;
  padding: 5px;
}
.outstanding {
  color: var(--Black-medium-text, rgba(0, 0, 0, 0.5));
  font-size: 14px;
  font-weight: 500;
}
.outstandingvalue {
  color: var(--Black-main-text, rgba(0, 0, 0, 0.8));
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.incomestyle {
  color: var(--Black-main-text, rgba(0, 0, 0, 0.8));
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.userdata {
  display: inline-flex;
  justify-content: space-between;
}
.username {
  margin-left: 23px;
  margin-top: 0;
}
.btnspagination {
  display: flex;
  flex-direction: row;
  width: 200px;
  margin: 10px;
  padding: 10px;
}
.container {
  padding: 10px !important;
}
.btnfilterf {
  margin-left: 500px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
}
.middle-line {
  border: 3px solid #f5f5f5 !important;
}
.iconandname {
  display: flex;
  margin: 5px;
}
.editanddelete {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Primary, #3c7bd4);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-customer-container {
  display: flex;
  height: 560px;
}

.contentdata {
  margin-right: 0%;
}

.titleandtext {
  display: flex;
  margin-top: 12px;
}
.heading-text {
  color: gray;
  font-size: 14px;
  font-weight: "500";
  text-transform: "uppercase";
  word-wrap: "break-word";
}
.text-heading {
  color: rgba(0 0 0 0.8);
  font-size: 14;
  font-weight: "600";
  word-wrap: "break-word";
}

.btn-customer-link {
  text-decoration: 3px underline !important;
  border-radius: 3px;
  text-decoration-color: var(--Secondary, #e4b535) !important;
  text-underline-offset: 17px !important;
  outline: none !important;
  background-color: #fff !important;
}

.custom-accordion-container {
  padding: 0px;
}

.top-nav-buttons:focus{
  outline: none !important;
  box-shadow: none;
}

p{
  margin-bottom: 0px;
}

.textarea-customer {
  width: 100% !important;
}

.textarea-comment-box{
  overflow: hidden;
}

@font-face {
  font-family: "WebFont-Ubuntu";
  src: local(Ubuntu), url(https://fonts.gstatic.com/s/ubuntu/v10/4iCs6KVjbNBYlgoKcg72nU6AF7xm.woff2);
}
.top-container .org-container,
.top-container .secure-band,
.zb-container {
  padding: 15px 30px;
  padding-right: 0px;
}

.zb-container .actions .btn {
  margin-left: 5px;
}
.statement-details-container {
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);
  max-width: 100%;
}
.customer-statement-container {
  border: 0.2px grey;
  margin-top: 20px;
}
.customer-statement-template {
  max-height: 365px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  font-family: Ubuntu, "WebFont-Ubuntu";
  font-size: 9pt;
  color: #333333;
  background: #ffffff;
}

.customer-header-content {
  font-size: 9pt;
  color: #333333;
  background-color: #ffffff;
}
.customer-template-body {
  padding: 0 0.4in 0 0.55in;
}
.customer-template-footer {
  height: 0.7in;
  font-size: 6pt;
  color: #aaaaaa;
  padding: 0 0.4in 0 0.55in;
  background-color: #ffffff;
}
.pcs-footer-content {
  word-wrap: break-word;
  color: #aaaaaa;
  border-top: 1px solid #adadad;
}

.customer-statement-label {
  color: #333333;
}
.customer-entity-title {
  font-size: 16pt;
  color: #000000;
}
.customer-orgname {
  font-size: 10pt;
  color: #333333;
}
.customer-customer-name {
  font-size: 9pt;
  color: #333333;
}
.customer-itemtable-header {
  font-size: 9pt;
  color: #ffffff;
  background-color: #3c3d3a;
}
.customer-itemtable-breakword {
  word-wrap: break-word;
}
.customer-breakrow-inside {
  page-break-inside: avoid;
}
.customer-breakrow-after {
  page-break-after: auto;
}
.pcs-img-fit-aspectratio {
  object-fit: contain;
  object-position: top;
}
.pcs-img-border {
  border: 1px solid #f5f4f3;
}

@page :first {
  @top-center {
    content: element(header);
  }
  margin-top: 0.7in;
}

.customer-template-header {
  padding: 0 0.4in 0 0.55in;
  height: 0.7in;
}

.customer-template-fill-emptydiv {
  display: table-cell;
  content: " ";
  width: 100%;
}
.inline {
  display: inline-block;
}
.v-top {
  vertical-align: top;
}
.text-align-right {
  text-align: right;
}
.rtl .text-align-right {
  text-align: left;
}
.text-align-left {
  text-align: left;
}
.rtl .text-align-left {
  text-align: right;
}
.float-section-right {
  float: right;
}
.rtl .float-section-right {
  float: left;
}
.float-section-left {
  float: left;
}
.rtl .float-section-left {
  float: right;
}

.rtl th {
  text-align: inherit; /* Specifically setting th as inherit for supporting RTL */
}

.customer-trclass_evenrow {
  background-color: #f6f5f5;
}
.customer-trclass_oddrow {
  background-color: #ffffff;
}

table {
  -fs-table-paginate: paginate;
}
.customer-title-section {
  float: right;
  margin-top: 20px;
}
.rtl .title-section {
  float: left;
}
.customer-itemtable-header {
  padding: 4px 4px;
}
.customer-summary-section {
  float: right;
}
.rtl .summary-section {
  float: left;
}
.customer-box-padding {
  padding: 8px 4px;
}
.custom-dropdown-toggle {
  background-color: #fff; /* Background color of the toggle button */
  color: #333; /* Text color of the toggle button */
  border-color: #ccc; /* Border color of the toggle button */
}

.custom-dropdown-menu {
  background-color: #fff; /* Background color of the dropdown menu */
  border: 1px solid #ccc; /* Border of the dropdown menu */
  border-radius: 5px; /* Border radius of the dropdown menu */
}

.custom-dropdown-item {
  color: #333; /* Text color of dropdown items */
}

.custom-dropdown-item:hover,
.custom-dropdown-item:focus {
  background-color: #f0f0f0; /* Background color of dropdown items on hover/focus */
}

.comment-content {
  display: flex;
  flex-direction: column;
}

.comment-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.comment {
  display: flex;
  margin-bottom: 10px;
}

.comment p {
  margin: 0; /* Remove default margin */
}
/**Comment section**/

.comments-history {
  padding: 25px 20px 20px 20px;
  max-height: 220px;
  overflow-y: auto;
}

.comment-container {
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}

.float-comment-icon {
  float: left !important;
}

.txn-comment-icon {
  background-color: #f6fbff;
  border-color: #d3dce4;
  position: relative;
  z-index: 2;
}

.circle-box {
  border: 1px solid #eee;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 1.7;
}

.media-body {
  max-width: 470px;
}

.comment-name {
  font-weight: 500;
  font-size: 16px;
}

.wrapper-commentbox {
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 7px;
}