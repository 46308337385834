.payments-made-section{
    height: calc(100vh - 100px);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payments-made-container{
    padding: 30px !important;
    width: 400px;
    height: 450px;
    border: 1px solid #ddd;
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.1);
}

.payments-made-content{
    row-gap: 20px;
}



    
