.template-page {
  padding: 20px;
}

.back-button {
  background-color: white;
  border-radius: 50%;
  height: 35px;
  width: 38px;
}

.arrow-icon {
  width: 24px;
}

.template-heading {
  letter-spacing: 1px;
  color: #086bd5de;
}

/* =============== */

.template-bg {
  background-color: #f9f9f9;
  /* Light background color */
  padding: 20px;
  border-radius: 8px;
  /* Rounded corners */
}

.template-gallery {
  display: flex;
  flex-wrap: wrap;
  /* Wrap items if necessary */
  justify-content: left;
  gap: 30px;
  padding: 20px;
  margin: 0 auto;
  /* Center the gallery */
}

.template-item {
  text-align: center;
  flex: 1 1 250px;
  max-width: 300px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.template-item.selected {
  border: 2px solid #006adb;
  background-color: #ddeeff;
  /* transform: scale(1.05); */
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
}

.template-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.template-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.template-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.template-button:hover {
  background-color: #0056b3;
}

.template-button.preview {
  background-color: #28a745; /* Different color for preview button */
}

.template-button.preview:hover {
  background-color: #218838;
}

.template-img {
  max-width: 100%;
  /* Ensure images fit within their container */
  height: 50vh;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  border: 1px solid lightgrey;
}

.template-item span {
  font-size: 18px;
  /* Slightly larger font size */
  color: #555;
  /* Darker text color */
  display: block;
  margin-top: 8px;
}


/* ================ */

.template-modal .modal-header {
  border-bottom: none;
}

.close-icon {
  font-size: 38px;
  color: #333;
}

.modal-image-container {
  text-align: center;
}

.modal-template-img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid lightgrey;
}

.template-bg {
  flex: 1;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}