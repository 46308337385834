/* Templates.css */
.templates-container {
  display: flex;
  height: 100vh; /* Full height */
}

.left-sidebar {
  width: 30%;
  background-color: #fffdfd;
  padding: 20px;
  height: 155vh; /* Full height */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.right-content {
  flex: 1; /* Take remaining width */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.bill{
  background-color: rgb(236, 239, 243);
  height: 40px;
}

.top-template-bar {
  background-color: rgb(60, 61, 62);
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  height: 100%; /* Full height */
}
.top-template-bar1 {
  color: #100404;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

 /* Templates.css */
.increased-border {
border: 4px solid; 
height: 1200px; /* Adjust the height as needed */
}

.section {
  margin-top:10px;
}
.section1 {
  margin-top:0px;
  flex: 1; /* Take remaining height */
}
.hello {
  justify-content: center;
align-items: center;
width: 100%;
}
.vertical-line {
  border-left: 1px solid #000; /* You can adjust the color and thickness as needed */
}

.payment-gateway{
  background-color: #ffffff; /* Background color */
  border: 1px solid #e0e0e0; /* Border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
  margin: 10px; 
}

.payment-gateway  .top-bar-icons {
  display: flex;
  align-items: center;
}

.payment-gateway button {
  background-color: #28a745; /* Green background color for the button */
  color: #ffffff; /* Text color for the button */
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

/* Your CSS file */
.invoice-data {
background-color: #ffffff;
border: 1px solid #e0e0e0;
border-radius: 4px;
padding: 20px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
margin: 10px;
}

.invoice-data .bill {
margin: 10px 0;
}

.invoice-data table {
width: 100%;
}

.invoice-data table th {
background-color: #f0f0f0;
font-weight: bold;
text-align: center;
}

.invoice-data table td {
text-align: center;
}
/* Add more styling as needed */
/* Templates.css */
.templates-container {
  display: flex;
  height: 100vh; /* Full height */
}

.left-sidebar {
  width: 30%;
  background-color: #fffdfd;
  padding: 20px;
  height: 155vh; /* Full height */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.right-content {
  flex: 1; /* Take remaining width */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.bill{
  background-color: rgb(236, 239, 243);
  height: 40px;
}

.top-template-bar {
  background-color: rgb(60, 61, 62);
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  height: 100%; /* Full height */
}
.top-template-bar1 {
  background-color: rgb(236, 239, 243);
  color: #100404;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

 /* Templates.css */
.increased-border {
border: 4px solid; 
height: 1200px; /* Adjust the height as needed */
}

.section {
  margin-top:10px;
   /* Take remaining height */
}
.section1 {
  margin-top:0px;
  flex: 1; /* Take remaining height */
}
.hello {
  justify-content: center;
align-items: center;
width: 100%;
}
.vertical-line {
  border-left: 1px solid #000; /* You can adjust the color and thickness as needed */
  /* height: 100%; */
}
.payment-gateway{
  background-color: #ffffff; /* Background color */
  border: 1px solid #e0e0e0; /* Border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
  margin: 10px; 
}

.payment-gateway  .top-bar-icons {
  display: flex;
  align-items: center;
}

.payment-gateway button {
  background-color: #28a745; /* Green background color for the button */
  color: #ffffff; /* Text color for the button */
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

/* Your CSS file */
.invoice-data {
background-color: #ffffff;
border: 1px solid #e0e0e0;
border-radius: 4px;
padding: 20px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
margin: 10px;
}

.invoice-data .bill {
margin: 10px 0;
}

.invoice-data table {
width: 100%;
}

.invoice-data table th {
background-color: #f0f0f0;
font-weight: bold;
text-align: center;
}

.invoice-data table td {
text-align: center;
}

.templates-container {
  display: flex;
  height: 100vh; /* Full height */
}

.left-sidebar {
  width: 30%;
  background-color: #fffdfd;
  padding: 20px;
  height: 155vh; /* Full height */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.right-content {
  flex: 1; /* Take remaining width */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.bill{
  background-color: rgb(236, 239, 243);
  height: 40px;
}

.top-template-bar {
  background-color: rgb(60, 61, 62);
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  height: 100%; /* Full height */
}
.top-template-bar1 {
  background-color: rgb(236, 239, 243);
  color: #100404;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

 /* Templates.css */
.increased-border {
border: 4px solid; 
height: 1200px; /* Adjust the height as needed */
}

.section {
  margin-top:10px;
   /* Take remaining height */
}
.section1 {
  margin-top:0px;
  flex: 1; /* Take remaining height */
}
.hello {
  justify-content: center;
align-items: center;
width: 100%;
}
.vertical-line {
  border-left: 1px solid #000; /* You can adjust the color and thickness as needed */
}

.payment-gateway{
  background-color: #ffffff; /* Background color */
  border: 1px solid #e0e0e0; /* Border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
  margin: 10px; 
}

.payment-gateway  .top-bar-icons {
  display: flex;
  align-items: center;
}

.payment-gateway button {
  background-color: #28a745; /* Green background color for the button */
  color: #ffffff; /* Text color for the button */
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

/* Your CSS file */
.invoice-data {
background-color: #ffffff;
border: 1px solid #e0e0e0;
border-radius: 4px;
padding: 20px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
margin: 10px;
}

.invoice-data .bill {
margin: 10px 0;
}

.invoice-data table {
width: 100%;
}

.invoice-data table th {
background-color: #f0f0f0;
font-weight: bold;
text-align: center;
}

.invoice-data table td {
text-align: center;
}

/* Add more styling as needed */
/* Templates.css */
.templates-container {
  display: flex;
  height: 100vh; /* Full height */
}

.left-sidebar {
  width: 30%;
  background-color: #fffdfd;
  padding: 20px;
  height: 155vh; /* Full height */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.right-content {
  flex: 1; /* Take remaining width */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.bill{
  background-color: rgb(236, 239, 243);
  height: 40px;
}

.top-template-bar {
  background-color: rgb(60, 61, 62);
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  height: 100%; /* Full height */
}
.top-template-bar1 {
  background-color: rgb(236, 239, 243);
  color: #100404;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
 /* Templates.css */
.increased-border {
border: 4px solid; 
height: 1200px; /* Adjust the height as needed */
}

.section {
  margin-top:10px;
}
.section1 {
  margin-top:0px;
  flex: 1; /* Take remaining height */
}
.hello {
  justify-content: center;
align-items: center;
width: 100%;
}
.vertical-line {
  border-left: 1px solid #000; /* You can adjust the color and thickness as needed */
}

.payment-gateway{
  background-color: #ffffff; /* Background color */
  border: 1px solid #e0e0e0; /* Border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
  margin: 10px; 
}

.payment-gateway  .top-bar-icons {
  display: flex;
  align-items: center;
}

.payment-gateway button {
  background-color: #28a745; /* Green background color for the button */
  color: #ffffff; /* Text color for the button */
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

/* Your CSS file */
.invoice-data {
background-color: #ffffff;
border: 1px solid #e0e0e0;
border-radius: 4px;
padding: 20px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
margin: 10px;
}

.invoice-data .bill {
margin: 10px 0;
}

.invoice-data table {
width: 100%;
}

.invoice-data table th {
background-color: #f0f0f0;
font-weight: bold;
text-align: center;
}

.invoice-data table td {
text-align: center;
}
