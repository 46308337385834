/* In your CSS file or style section */
.bankacc-dropdown-toggle {
  border: none; /* Remove the border */
  outline: none; /* Remove the outline */
  box-shadow: none; /* Remove any box shadow */
}

.drop-menu {
  background-color: aliceblue;
}

.dropdown-text {
  color: aliceblue;
}

.icon-border {
  border: 1px solid #d3c9c9;
  padding: 2px 6px;
  border-radius: 5px;
}
