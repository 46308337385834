.balance-sheet-table th,td{
    padding: 10px 30px;
}
.text-semi-bold{
    font-weight: 600;
}
.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.pl-6{
    padding-left: 64px;
}