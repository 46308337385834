.repeatevery-inputchild {
    display: flex;
    align-items: flex-end;
    margin-left: 19px;
  }
  .repeatevery-inputchild input[type="text"],
  textarea {
    background-color: rgb(245, 245, 245);
    border-radius: 9px;
    margin-top: 10px;
    font-size: 13px;
    height: 40px;
    width: 60px !important;
  }