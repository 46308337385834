.unusedcreditcontainer {
  margin-left: 30px;
}

.custom-textarea-payment {
  background-color: #f6f6f6;
  border-radius: 9px !important;
  padding: 12px;
  width: 100% !important;
  display: block;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-textarea-payment:hover {
  border-color: #2c93ff !important;
}

.payments-btn-1{
    display: inline-flex;
    margin-right: 20px;
    height: 40px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: #3c7bd4;
    flex-shrink: 0;
    border-radius: 7px;
    border-radius: 7px;
    border: 1px solid var(--Primary, #3c7bd4) !important;
    background: var(--Card-color, #fff);
}

.payments-btn-3{
    display: inline-flex;
    height: 40px;
    padding: 10px 33px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 7px;
    border: 1px solid var(--Primary, #3c7bd4);
    background: var(--Primary, #3c7bd4);
}


