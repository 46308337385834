/* Reports.css */
.heading-with-underline {
    border-bottom: 2px dotted #b59c9c; /* Adjust the styling as needed */
    padding-bottom: 5px; 
    width: 100%;
  }
  .heading-with-dotted-underline {
    border-bottom: 1px dotted #000; /* Adjust the styling as needed */
    padding-bottom: 5px; 
    width: 100%;
}

  .container {
    padding: 20px; /* Add padding to the container */
  }
  
  .report-section{
    margin-bottom: 30px;
    max-width: 250px;
  }

  .reports-border-bottom{
    display: block;
    width: 100%;
    border-bottom: 1px dashed #e9e9e9;
    padding: 10px 0;
  }