.onoffswitch {
    position: relative;
    width: 50px;
    height: 23px;
    background-color: #ddd;
    border-radius: 12px;
    cursor: pointer;
}

.onoffswitch .toggle {
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

.onoffswitch.on .toggle {
    transform: translateX(30px);
    background-color: #0083ff;
}

.onoffswitch.off .toggle {
    transform: translateX(0);
}

.onoffbg {
    width: 60%;
    padding: 2cap;
    border: none;
    border-radius: 5px;
    background-color: rgb(238, 238, 238);
}

.templates-container{
    display: flex;
    height: auto;
    height: 100%;
}

.left-invoice {
    width: 20%;
    background-color: #fffdfd;
    border-right: 1px solid lightgrey;
    border-radius: 12px 0 0 12px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
    padding: 10px; /* Add padding if necessary */
}

.right-invoice {
    background-color: #fffdfd;
    flex: 1;
    border-radius: 0 12px 12px 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
    padding: 10px; /* Add padding if necessary */
}


.taxesname {
    padding: 12px;
    background-color: white;
    border-radius: 6px;
    color: black;
    outline: none;
}

span{
    font-size: 14px;
    /* color: grey;  */
}

.button1{
    padding: 10px;
    width: 60px;
    border: none;
    border-radius: 6px;
    background-color: white;    
}

.button2{
    padding: 10px;
    width: 60px;
    border: none;
    border-radius: 6px; 
}

.btnsave{
    background-color: #0083ff;
    color: white;
}