.useroptions-dropdown {
  position: absolute;
  top: 56px;
  left: -92px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 7px;
}

.useroptions-option1 {
  /* border-top-left-radius: 7px;
  border-top-right-radius: 7px; */
  padding: 10px 50px 10px 2.2px;
}

.useroptions-option2 {
  /* border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px; */
  padding: 10px 50px 10px 14px;
}

.useroptions-option1:hover {
  background-color: #3c7bd4;
  color: white;
}

.useroptions-option2:hover {
  background-color: #3c7bd4;
  color: white;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  color: gray;
}

.search-input {
  padding-left: 35px;
  /* Space for icon */
  width: 100%;
  border-radius: 10px;
}

.search-bar-container:hover {
  width: 450px;
  border-color: #3c7bd4;
}

.dropdown-item {
  padding: 15px;
  background: white;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-size:16px;
    color: #333;
    height: 50px;
}

.dropdown-item :hover {
  background-color: #f1f1f1;
  color: #3c7bd4;
  border-radius: 10px;

}