
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;   }

  .income-circle {
    background-color: rgb(80, 126, 80); /* Choose the color for the income circle */
  }

  .expenses-circle {
    background-color: red; /* Choose the color for the expenses circle */
  } 
  
  .inr-circle {
    align-items: center;
    background-color: rgb(80, 99, 126); /* Choose the color for the income circle */
  }
